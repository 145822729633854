const documentsRoutes = [
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/documents/DocumentsList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.Documents',
          link: '/documents',
        },
      ],
    },
  },
];
export default documentsRoutes;
