export default function (instance) {
  const urlPrefix = '/documents';

  return {
    create: async function (data) {
      return await instance.post(`${urlPrefix}/`, data);
    },
    getList: async function (params = {}) {
      return await instance.get(`${urlPrefix}/`, {
        params: params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    getInfo: async function () {
      return await instance.get(`${urlPrefix}/prepare`);
    },
    getById: async function (id) {
      return await instance.get(`${urlPrefix}/${id}`);
    },
    editById: async function ({ id, data }) {
      return await instance.put(`${urlPrefix}/${id}`, data);
    },
    removeById: async function (id) {
      return await instance.delete(`${urlPrefix}/${id}`);
    },
  };
}
