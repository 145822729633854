<template>
  <h-preloader :type="$theme.typeLoader" :loading="preloader" />
  <RouterView
    v-if="!preloader"
    :key="route.meta?.usePathKey ? route.path : undefined"
  />
  <div id="wrapper-selector-portal"></div>
  <div v-if="needUpdateClaim" class="claim">
    <div
      class="claim__inner"
      @click="reloadPage"
      v-html="$t('Main.needUpdate')"
    ></div>
  </div>

  <confirm-modal
    :visible="logoutConfirmation.visible"
    :title-modal="t('User.confirmLogoutTitle')"
    :btn-success-text="$t('Base.yes')"
    :btn-cancel-text="$t('Base.no')"
    :show-text-area="false"
    @close="logoutConfirmation.visible = false"
    @submit="logout"
  />
  <h-alert-list :offset-top="70" />
</template>

<script setup>
import { HAlertList, HPreloader } from '@homemg/ui-kit';
import { storeToRefs } from 'pinia';
import { getCurrentInstance, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterView, useRoute } from 'vue-router';

import api from '@/api';
import ConfirmModal from '@/components/ConfirmModal.vue';
import usePreloaderStore from '@/stores/preloader.js';
import useUserStore from '@/stores/user.js';
import { initHeader } from '@/utils/header/index.js';
const userInfo = useUserStore();
const app = getCurrentInstance();

const { t } = useI18n();

const route = useRoute();

if (app.appContext.config.globalProperties.$theme.isMyGames) {
  import('@/assets/styles/themes/mygames.css');
} else {
  import('@/assets/styles/themes/astrum.css');
}

const { preloader } = storeToRefs(usePreloaderStore());

initHeader();

app.appContext.config.globalProperties.$lang =
  window.Header.getLanguage() === 'en_US' ? 'en' : 'ru';

const needUpdateClaim = ref(false);
const logoutConfirmation = ref({
  visible: false,
  onAction: null,
});

const logout = () => {
  if (userInfo?.user?.view_as_mode_enabled) {
    logoutViewAs();
  } else {
    logoutConfirmation.value.onAction(true);
  }
};

onMounted(() => {
  document.addEventListener(
    'HEADER_LOGOUT_CONFIRM_DIALOG_SHOW',
    ({ detail }) => {
      logoutConfirmation.value.visible = true;
      logoutConfirmation.value.onAction = detail?.onAction;
    }
  );

  window.addEventListener('vite:preloadError', () => {
    needUpdateClaim.value = true;
  });
});

const reloadPage = () => {
  window.location = window.location;
};

const logoutViewAs = async () => {
  try {
    await api.admin.logoutAsUser();

    reloadPage();
  } catch (e) {
    console.error('Error setUser:', e);
  }
};
</script>

<style lang="less">
@import '@homemg/ui-kit/less/style.less';

:root {
  --app-font-family: var(--p-app-font-family);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--app-background-global);
  font-family: var(--app-font-family);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#app {
  font-family: var(--p-app-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-height: 100%;
}

a {
  color: var(--p-app-link-color);
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.h-alerts {
  z-index: 1000;
}

.claim {
  margin: 10px auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  bottom: 0;
  max-width: 700px;
  padding: 0 20px;

  &__inner {
    width: calc(100% - 40px);
    padding: 15px 20px;
    border-radius: 10px;
    color: #fff;
    background: rgb(255, 15, 15);
    box-shadow: var(--app-shadow-3);
    margin: 0 auto;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
</style>
