export default function (instance) {
  const urlPrefix = '/intranet';

  return {
    getArea: async function () {
      return await instance.get(`${urlPrefix}/area/`);
    },
    getLegalEntity: async function () {
      return await instance.get(`${urlPrefix}/legal_entity/`);
    },
    getCFO: async function () {
      return await instance.get(`${urlPrefix}/cfo/`);
    },
  };
}
