export default function (instance) {
  const urlPrefix = '/user';

  return {
    self: async function () {
      return await instance.get(`${urlPrefix}/self`);
    },
    getList: async function ({ limit = 10, offset = 0, search = '' }) {
      return await instance.get(`${urlPrefix}/`, {
        params: { limit, offset, search },
      });
    },
    permissions: async function () {
      return await instance.get(`${urlPrefix}/permissions`);
    },
  };
}
