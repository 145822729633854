import '@/css/helpers.less';

import { vClickOutside } from '@homemg/ui-kit/directives';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueTheMask from 'vue-the-mask';

import App from '@/App.vue';
import currency from '@/filters/currency';
import i18n from '@/i18n';
import useApiPlugin from '@/plugins/api';
import router from '@/router';

const app = createApp(App)
  .use(i18n)
  .use(createPinia())
  .use(router)
  .use(useApiPlugin)
  .use(VueTheMask)
  .directive('click-outside', vClickOutside);

app.config.globalProperties.$filters = {
  currency,
};

app.config.globalProperties.$theme = {
  name: import.meta.env.VITE_APP_THEME,
  typeLoader:
    import.meta.env.VITE_APP_THEME === 'astrum' ? 'spinner' : 'mygames',
  isMyGames: import.meta.env.VITE_APP_THEME === 'mygames',
  isAstrum: import.meta.env.VITE_APP_THEME === 'astrum',
};

app.mount('#app');
