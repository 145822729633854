const contractorsRoutes = [
  {
    path: '/contractors',
    name: 'Contractors',
    component: () => import('@/views/contractors/ContractorsList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.ContractorsManagement',
          link: '/contractors',
        },
      ],
    },
  },
];
export default contractorsRoutes;
