<template>
  <h-modal :visible="visible" :style-modal="styleModal" @close="$emit('close')">
    <template #header>
      <h2 class="modal__heading">{{ titleModal }}</h2>
    </template>
    <p v-if="mainText" class="modal__text">{{ mainText }}</p>
    <p v-if="label" class="modal__label">{{ label }}</p>
    <base-textarea
      v-if="showTextArea"
      :model-value="modelValue"
      class="modal__textarea"
      :rows="4"
      :max-length="280"
      :placeholder="placeholder"
      :show-symbols-left="showSymbolsLeft"
      @update:model-value="val => $emit('update:model-value', val)"
    />
    <template #footer>
      <div class="modal__buttons">
        <h-button
          class="modal__button"
          type="secondary"
          :disabled="saving"
          :label="btnCancelText || $t('Base.Cancel')"
          uppercase
          @click="$emit('close')"
        />
        <h-button
          class="modal__button"
          :disabled="saving || (requireComment && !modelValue)"
          :loading="saving"
          :label="btnSuccessText || $t('Base.Save')"
          uppercase
          @click="$emit('submit')"
        />
      </div>
    </template>
  </h-modal>
</template>

<script setup>
import { HButton, HModal } from '@homemg/ui-kit';
import { useI18n } from 'vue-i18n';

import BaseTextarea from '@/ui/BaseTextarea.vue';
import { computed } from 'vue';

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  requireComment: {
    type: Boolean,
    default: false,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  saving: {
    type: Boolean,
    default: false,
  },
  titleModal: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  mainText: {
    type: String,
    default: '',
  },
  btnCancelText: {
    type: String,
    default: '',
  },
  btnSuccessText: {
    type: String,
    default: '',
  },
  showTextArea: {
    type: Boolean,
    default: true,
  },
  showSymbolsLeft: {
    type: Boolean,
    default: false,
  },
  styleModal: {
    type: Object,
    default: () => ({ maxWidth: '600px', width: '600px', minWidth: 'initial' }),
  },
});

defineEmits(['update:model-value', 'close', 'submit']);
</script>

<style lang="less" scoped>
@import '@/css/mixins.less';
.modal {
  &__heading {
    .title();
    font-size: 30px;
    line-height: 35px;
    margin: 0;
  }

  &__textarea {
    margin-bottom: 15px;
  }

  &__buttons {
    display: flex;
    gap: 15px;
    width: 100%;
    justify-content: flex-end;
  }

  &__button {
    width: 160px;
  }

  &__label {
    font-size: 16px;
    margin-bottom: 5px;
    color: var(--app-dark);
    font-weight: bold;
    font-family: var(--p-app-title-font-family);
  }

  &__text {
    font-size: 16px;
    color: var(--app-dark);
    margin: 0 0 50px 0;
  }
}

@media (max-width: 576px) {
  .modal {
    &__heading {
      font-size: 16px;
      line-height: 19px;
    }

    &__textarea {
      margin-bottom: 26px;
    }

    &__buttons {
      text-align: center;
      flex-wrap: wrap;
    }

    &__button {
      font-size: 10px;
      line-height: 11px;
      border-radius: 5px;
      flex-grow: 1;
    }
  }
}

@media (max-width: 375px) {
  .modal {
    &__button {
      min-width: 140px;
    }
  }
}
</style>
