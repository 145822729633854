export default function (instance) {
  return {
    upload: async function (payload, headers = {}) {
      return await instance.post(`/attachments/`, payload, {
        headers: headers,
      });
    },
    getById: async function (id) {
      return await instance.get(`/attachments/${id}`);
    },
    attach: async function ({ idFile, typeObject, idObject }) {
      return await instance.post(`/attachments/${idFile}/attach`, {
        relation_type: typeObject,
        relation_id: idObject,
      });
    },
    removeById: async function (idFile) {
      return await instance.delete(`/attachments/${idFile}`);
    },
  };
}
