const adminRoutes = [
  {
    path: '/admin',
    name: 'Admin panel',
    component: () => import('@/views/admin/Index.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.AdminPanel',
          link: '/admin',
        },
      ],
    },
  },
  {
    path: '/admin/chain-management',
    name: 'Chain Management List',
    component: () => import('@/views/admin/ChainManagementList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.AdminPanel',
          link: '/admin',
        },
        {
          titleKey: 'Sections.ChainManagement',
          link: '/admin/chain-management',
        },
      ],
    },
  },
];
export default adminRoutes;
