<template>
  <div class="base-textarea-wrapper">
    <h-textarea
      class="base-textarea"
      :placeholder="placeholder"
      :rows="rows"
      :maxlength="maxLength"
      :model-value="modelValue"
      :style-input-content="styleInputContent"
      @update:model-value="val => $emit('update:model-value', val)" />
    <div v-if="!showSymbolsLeft" class="symbols-left-bubble">
      <span class="symbols-left-text">
        {{ $t('Base.SymbolsLeft', symbolsLeft, { count: symbolsLeft }) }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { HTextarea } from '@homemg/ui-kit';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 3,
  },
  showSymbolsLeft: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 250,
  },
  styleInputContent: {
    type: Object,
    default: () => {},
  },
});

defineEmits(['update:model-value']);

const currentLength = computed(() => props.modelValue.length);
const symbolsLeft = computed(() => props.maxLength - currentLength.value);
</script>

<style lang="less" scoped>
.base-textarea-wrapper {
  position: relative;
}

.symbols-left-bubble {
  position: absolute;
  right: 2px;
  bottom: -16px;
  padding: 0 10px;
  height: 30px;
  background-color: #fff;
  box-shadow: var(--app-shadow-2);
  border-radius: 10px;
}

.symbols-left-text {
  color: #2d2d2d80;
  font-size: 10px;
  line-height: 30px;
  font-weight: 500;
}

@media (max-width: 576px) {
  .base-textarea {
    font-size: 12px;
    line-height: 18px;
  }

  .symbols-left-bubble {
    height: 21px;
    border-radius: 4px;
    bottom: -7px;
  }

  .symbols-left-text {
    line-height: 21px;
  }
}
</style>
