export default function (instance) {
  const urlPrefix = '/funds';

  return {
    list: async function (params, source) {
      const sourceUrl = source ? `${source}/` : '';
      return await instance.post(`${urlPrefix}/${sourceUrl}`, params);
    },
    getOne: async function (guid, source) {
      return await instance.get(`${urlPrefix}/${source}/${guid}`);
    },
    addFile: async function (guid, formData, source) {
      return await instance.post(
        `${urlPrefix}/${source}/${guid}/file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: 'application/json',
          },
        }
      );
    },
    getFile: async function (guid, fileGuid, source) {
      return await instance.get(
        `${urlPrefix}/${source}/${guid}/file/${fileGuid}`
      );
    },
    approveOne: async function (guid, guidStage, source) {
      return await instance.post(
        `${urlPrefix}/${source}/${guid}/agreed/${guidStage}`
      );
    },
    rejectOne: async function (guid, guidStage, source) {
      return await instance.post(
        `${urlPrefix}/${source}/${guid}/decline/${guidStage}`
      );
    },
    commentOne: async function (guid, comment, source) {
      return await instance.post(`${urlPrefix}/${source}/${guid}/comment`, {
        comment,
      });
    },
    approveMany: async function (guidsList, source) {
      return await instance.post(`${urlPrefix}/${source}/agreed`, guidsList);
    },
    rejectMany: async function (guidsList, source) {
      return await instance.post(`${urlPrefix}/${source}/decline`, guidsList);
    },
    commentMany: async function (guidList, comment, source) {
      return await instance.post(`${urlPrefix}/${source}/comment`, {
        guid_list: guidList,
        comment,
      });
    },
    getFilterStatuses: async function () {
      return await instance.get(`${urlPrefix}/filters/statuses`);
    },
    getFilterUsers: async function ({ search = '', limit = 10, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/users`, {
        params: { limit, offset, search },
      });
    },
    getFilterLegalEntity: async function ({
      limit = 100,
      offset = 0,
      search = '',
    }) {
      return await instance.get(`${urlPrefix}/filters/legal_entities`, {
        params: { limit, offset, search },
      });
    },
    getFilterDocuments: async function ({
      limit = 10,
      offset = 0,
      search = '',
    }) {
      return await instance.get(`${urlPrefix}/filters/documents`, {
        params: { limit, offset, search },
      });
    },
    getFilterPartners: async function ({
      limit = 10,
      offset = 0,
      search = '',
    }) {
      return await instance.get(`${urlPrefix}/filters/partners`, {
        params: { limit, offset, search },
      });
    },
    getFilterProjects: async function ({
      limit = 10,
      offset = 0,
      search = '',
    }) {
      return await instance.get(`${urlPrefix}/filters/projects`, {
        params: { limit, offset, search },
      });
    },
    getFilterCFO: async function ({ limit = 10, offset = 0, search = '' }) {
      return await instance.get(`${urlPrefix}/filters/frc`, {
        params: { limit, offset, search },
      });
    },
    getFilterMasterAccounts: async function ({
      limit = 10,
      offset = 0,
      search = '',
    }) {
      return await instance.get(`${urlPrefix}/filters/master_accounts`, {
        params: { limit, offset, search },
      });
    },
  };
}
