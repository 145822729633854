import { defineStore } from 'pinia';
import { ref } from 'vue';

const usePreloaderStore = defineStore('preloader', () => {
  const preloader = ref(true);

  function show() {
    preloader.value = true;
  }

  function hide() {
    preloader.value = false;
  }

  function trigger() {
    preloader.value = !!preloader.value;
  }

  return { preloader, show, hide, trigger };
});

export default usePreloaderStore;
