export default function (instance) {
  const urlPrefix = '/civil_contracts';

  return {
    getList: async function (params) {
      return await instance.get(`${urlPrefix}/`, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    blockEmployee: async function (id) {
      return await instance.post(`${urlPrefix}/block`, { uid: id });
    },
    extendAccessEmployee: async function (id) {
      return await instance.post(`${urlPrefix}/extend_access`, {
        uid: id,
      });
    },
    updateManager: async function (params) {
      return await instance.post(`${urlPrefix}/update_manager`, params);
    },
  };
}
