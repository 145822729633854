const documentsRoutes = [
  {
    path: '/income',
    name: 'IncomeList',
    component: () => import('@/views/income/IncomeList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
      ],
    },
  },
];
export default documentsRoutes;
