export default function (instance) {
  const urlPrefix = '/services';

  return {
    list: async function () {
      return await instance.get(`${urlPrefix}/`);
    },
    getInfo: async function () {
      return await instance.get(`${urlPrefix}/info`);
    },
  };
}
