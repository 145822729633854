import { useBrowserTab } from '@homemg/ui-kit/composables';
import { createRouter, createWebHistory } from 'vue-router';

import i18n from '@/i18n';
import useUserStore from '@/stores/user';
import { setLanguage } from '@/utils/header';

import documentsRoutes from './documents';
import reservingRoutes from './reserving';
import tripsRoutes from './trips';
import vacationRoutes from './vacations';
import incomeRoutes from './income';
import teamRoutes from './team';
import adminRoutes from './admin';
import contractorsRoutes from './contractors';

const { setTitlePage } = useBrowserTab();
const HOME_URL = import.meta.env.VITE_INTRANET_URL;

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: () => import('@/views/MainPage.vue'),
    meta: {},
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter(to, from, next) {
      window.location.href =
        '/api/v1/auth/oauth/login?redirect_url=' +
        encodeURI(window.location.href);
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/errors/PageNotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundRoute',
    redirect: { name: 'NotFound' },
  },
  ...vacationRoutes,
  ...reservingRoutes,
  ...tripsRoutes,
  ...documentsRoutes,
  ...incomeRoutes,
  ...teamRoutes,
  ...adminRoutes,
  ...contractorsRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeResolve(async to => {
  const headerLang = window.Header.getLanguage();
  if (
    headerLang !== i18n.global.locale.value ||
    !window.Header.getLanguageCookie()
  ) {
    setLanguage(headerLang);
  }
  setTitle(to);

  const userStore = useUserStore();
  if (!userStore.user.value) {
    return await userStore.load();
  }
  return false;
});

function setTitle(to) {
  let breadcrumbsHeader = [];
  try {
    if (typeof to?.meta?.breadcrumbsHeader === 'function') {
      breadcrumbsHeader = to?.meta?.breadcrumbsHeader(to);
    } else {
      breadcrumbsHeader = to?.meta?.breadcrumbsHeader;
    }

    const lastBreadcrumbText = breadcrumbsHeader?.length
      ? breadcrumbsHeader[breadcrumbsHeader.length - 1]?.titleKey
      : 'Sections.PersonalAccount';

    setTitlePage(i18n.global.t(lastBreadcrumbText));
  } catch (e) {
    console.error(e);
  }
}

export default router;
