export default function (instance) {
  const urlPrefix = '/countries';

  return {
    getCountries: async function ({ limit = 10, offset = 0, search = '' }) {
      return await instance.get(`${urlPrefix}/`, {
        params: {
          limit,
          offset,
          search,
        },
      });
    },
    getCities: async function ({
      limit = 10,
      offset = 0,
      search = '',
      countryId,
    }) {
      return await instance.get(`${urlPrefix}/${countryId}/cities/`, {
        params: {
          limit,
          offset,
          search,
        },
      });
    },
  };
}
