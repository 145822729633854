import { createI18n } from 'vue-i18n';

const VITE_APP_THEME = import.meta.env.VITE_APP_THEME;
const en_US = (await import(`@/i18n/locales/${VITE_APP_THEME}/en_US.json`))
  .default;
const ru_RU = (await import(`@/i18n/locales/${VITE_APP_THEME}/ru_RU.json`))
  .default;

const setDateTimeFormats = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
  },
};

// function loadLocaleMessages() {
// 	const locales = require.context(
// 		"./i18n/locales/",
// 		true,
// 		/[A-Za-z0-9-_,\s]+\.json$/i
// 	);
// 	const messages = {};
// 	locales.keys().forEach((key) => {
// 		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
// 		if (matched && matched.length > 1) {
// 			const locale = matched[1];
// 			messages[locale] = locales(key);
// 		}
// 	});
// 	return messages;
// }

function customRule(choice, choicesLength) {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;
  if (choicesLength < 4) {
    return !teen && endsWithOne ? 1 : 2;
  }
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
}

const dateTimeFormats = {
  en_US: setDateTimeFormats,
  ru_RU: setDateTimeFormats,
};

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  inheritLocale: true,
  availableLocales: ['ru_RU', 'en_US'],
  locale: 'ru_RU',
  fallbackLocale: 'ru_RU', // set fallback locale
  messages: {
    ru_RU,
    en_US,
  },
  dateTimeFormats,
  pluralRules: {
    ru_RU: customRule,
  },
  escapeParameterHtml: false,
});

export default i18n;
