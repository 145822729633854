const HOME_URL = import.meta.env.VITE_INTRANET_URL;
const reservingRoutes = [
  {
    path: '/reserving',
    name: 'Reserve Funds',
    component: () => import('@/views/reserving/Reserving.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.Reserving',
          link: '/reserving',
        },
      ],
    },
  },
  {
    path: '/reserving/:source?/:guid/:documentId?',
    alias: '/reserving/:source?/:guid/:documentId?',
    name: 'Reserve Application',
    component: () => import('@/views/reserving/ReserveApplication.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
      ],
    },
  },
];
export default reservingRoutes;
