import enums from '@/utils/enums';

const tripsRoutes = [
  {
    path: '/team',
    name: 'My Team',
    component: () => import('@/views/team/TeamList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTeam',
          link: '/team',
        },
      ],
    },
  },
  {
    path: '/team/:id',
    name: 'Employee Card',
    component: () => import('@/views/team/EmployeeCard.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTeam',
          link: '/team',
        },
      ],
    },
  },
  {
    path: '/team/absence-calendar',
    name: 'Absence Calendar',
    component: () => import('@/views/team/AbsenceCalendar.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTeam',
          link: '/team',
        },
        {
          titleKey: 'Sections.AbsenceCalendar',
          link: '/team/absence-calendar',
        },
      ],
    },
  },
  {
    path: '/team/probations/:id',
    name: 'Probation Card',
    component: () => import('@/views/team/ProbationCard.vue'),
    meta: {
      usePathKey: true,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTeam',
          link: '/team',
        },
        {
          titleKey: 'Sections.Probations',
          link: '/team/probations',
        },
      ],
    },
  },
  {
    path: '/team/probations',
    name: 'Probations List',
    component: () => import('@/views/team/ProbationsList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTeam',
          link: '/team',
        },
        {
          titleKey: 'Sections.Probations',
          link: '/team/probations',
        },
      ],
    },
  },
];
export default tripsRoutes;
