export default function (instance) {
  const urlPrefix = '/income';

  return {
    getDataByYear: async function (year) {
      return await instance.get(`${urlPrefix}/${year}`);
    },
    getInfo: async function () {
      return await instance.get(`${urlPrefix}/info`);
    },
  };
}
