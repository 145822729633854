export const initialSettings = {
  service_name: 'home',
  home_url: '/',
  shows: {
    action: true,
    header: true,
    logo: true,
    navigation: false,
    dashboard: false,
    apps: false,
    questions: false,
    notifies: false,
    languages: false,
    user: false,
    beta: true,
  },
  sections: [],
  params: {
    replace_header_title: true,
    set_header_position: 'fixed',
    set_active: '',
    scratch: [30],
    padding_container: '#app',
    do_padding: false,
    transparent: true,
    language_callback: 'languageMenu',
  },
  languages: [
    { key: 'ru_RU', title: 'Russian' },
    // { key: "en_US", title: "English" },
  ],
  // "languages": [{"key": "en_US", "title": "English"}],
  user: {},
  apps: [],
};
